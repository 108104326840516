body.halloween {
  --brand-primary: #ff5722;
  --brand-secondary: #d77418;
  --brand-success: #2196f3;
  --brand-warning: #f1c40f;
  --brand-danger: #f44336;
  --brand-info: #4caf50;

  --sidebar-bg: rgba(10, 21, 27, 0.95);
  --sidebar-bg-secondary: #0e191f;
  --sidebar-text: white;
  --sidebar-text-lighter: #c9c7c7;

  --brand-inverse-text: white;
  --brand-primary-lite-bg-color: white;
  --brand-primary-text-color: #fbfbfb;
  --brand-dark-bg-color: #000;
  --brand-dark-bg-color-transparent: rgba(10, 10, 10, 0.7);
  --brand-bg-lite-transparent: rgb(19, 40, 51);

  --link-primary-color: var(--brand-primary);
  --link-primary-hover-color: var(--brand-primary);

  --navbar-bg-color: rgba(10, 21, 27, 0.95);
  --navbar-text-color: #fbfbfb;
  --navbar-link-color: var(--link-primary-color);

  --list-group-item-text: var(--sidebar-text);
  --list-group-item-text-hover: var(--sidebar-text);
  --list-group-item-bg: var(--sidebar-bg-secondary);
  --list-group-item-bg-hover: var(--brand-bg-lite-transparent);
  --list-group-item-border: var(--brand-bg-lite-transparent);

  --player-controls-bar-bg: var(--navbar-bg-color);
  --media-bg-primary: var(--sidebar-bg-secondary);

  --app-bg-color: #0b161b;
}
